import { Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, SelectProps, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface ICustomSelect extends SelectProps {
    onChange: (e: any) => void;
    value: string[];
    data: any[];
    defaultValue?: string | string[];
    selectProps?: SelectProps;
    disabled?: boolean;
    multiple?: boolean;
    name: string;
    label: string;
    size?: 'small' | 'medium';
    componentSx?: Object;
}

const CustomSelectCenter = ({
    onChange,
    data,
    value,
    defaultValue,
    disabled = false,
    multiple = false,
    name,
    label,
    selectProps,
    size = 'small',
    componentSx
}: ICustomSelect) => {
    const [searchTerm, setSearchTerm] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);

    // Hàm để lấy tên của các center đã chọn
    const getSelectedCenterNames = () => {
        if (!value || !data) return '';

        if (multiple) {
            const selectedCenters = data.filter((center) => value.includes(center.id));
            if (selectedCenters.length === data.length) return `All centers`;
            if (selectedCenters.length === 0) return '';
            if (selectedCenters.length === 1) return selectedCenters[0].nameCenter;
            return `${selectedCenters[0].nameCenter} (+${selectedCenters.length - 1})`;
        }

        const selectedCenter = data.find((center) => center.id === value);
        return selectedCenter ? selectedCenter.nameCenter : '';
    };

    const handleOnChange = (event: any) => {
        const newValue = event.target.value;

        // Xử lý logic chọn tất cả
        if (multiple && newValue.includes('All')) {
            const currentValues = value || [];
            const allCenterIds = data.map((center) => center.id);

            // Nếu đã chọn tất cả thì bỏ chọn hết, ngược lại thì chọn tất cả
            const updatedValue = currentValues.length === allCenterIds.length ? [] : allCenterIds;

            onChange({
                ...event,
                target: { ...event.target, value: updatedValue }
            });
            return;
        }

        // Gọi onChange với giá trị mới
        onChange(event);
    };

    const filteredOptions = data.filter((option: any) => option?.nameCenter.toLowerCase().includes(searchTerm.toLowerCase()));

    useEffect(() => {
        searchInputRef.current?.focus();
    }, [searchTerm]);

    const handleSearchClick = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        searchInputRef.current?.focus();
    };
    // Xử lý sự kiện click vào TextField
    const handleTextFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <FormControl variant="outlined" sx={{ minWidth: 280, maxWidth: 360, ...componentSx }}>
            <InputLabel size="small" id={`${name}-label`}>
                {label}
            </InputLabel>
            <Select
                labelId={`${name}-label`}
                disabled={disabled}
                id={name}
                name={name}
                label={label}
                value={value || (multiple ? [] : '')}
                onChange={handleOnChange}
                onClose={() => setSearchTerm('')}
                size={size}
                multiple={multiple}
                renderValue={getSelectedCenterNames}
                {...selectProps}
            >
                <MenuItem onClick={handleSearchClick}>
                    <TextField
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={handleTextFieldClick}
                        onKeyDown={(e) => {
                            // Ngăn chặn sự kiện keydown lan truyền lên Select
                            e.stopPropagation();
                            // Ngăn chặn đóng Select khi nhấn Enter
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        inputRef={searchInputRef}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </MenuItem>
                {multiple && filteredOptions.length > 0 && (
                    <MenuItem value="All">
                        <Checkbox
                            checked={value?.length === data.length}
                            indeterminate={value?.length > 0 && value?.length < data.length}
                        />
                        <ListItemText primary="Select All" />
                    </MenuItem>
                )}
                {filteredOptions.length > 0 ? (
                    filteredOptions.map((center: any) => (
                        <MenuItem key={center.id} value={center.id}>
                            {multiple && <Checkbox checked={value?.includes(center.id)} />}
                            <ListItemText primary={center.nameCenter} />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" style={{ justifyContent: 'center' }}>
                        No data
                    </MenuItem>
                )}
                {/* {data.length === 0 && <MenuItem value="">No data</MenuItem>} */}
            </Select>
        </FormControl>
    );
};

export default CustomSelectCenter;

import * as React from 'react';

// material-ui
import { Box, Button, CardContent, Chip, Grid, InputAdornment, Stack, TablePagination, TextField, Tooltip } from '@mui/material';

// assets
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import brandApi from 'services/api/brandApi';
import { DEFAULT_REQUEST, Order } from 'config';
import UIManager from 'core/UIManager';
import { useDebounce } from 'services/hooks/useDebounce';
import { UserKechoProfile } from 'types/kecho/user-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import BrandForm from './components/BrandForm';
import DeleteBrandModal from './components/DeleteBrandModal';
import moment from 'moment';

interface Row extends UserKechoProfile {
    nameBranch?: string;
    center?: [];
    actions?: string;
}

// ==============================|| CUSTOMER LIST ||============================== //

const BrandList = () => {
    const [brandList, setBrandList] = React.useState<UserKechoProfile[]>([]);
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    const cols: IColumnTable<Row>[] = [
        {
            field: 'nameBranch',
            isSort: true,
            headerName: 'Brand Name',
            width: '30%'
        },
        {
            field: 'center',
            isSort: true,
            headerName: 'Centers',
            width: '40%',
            render: (row) => (
                <>
                    {row?.center?.map((item: any) => (
                        <Chip label={item?.nameCenter} sx={{ mb: 1, mr: 0.5 }} />
                    ))}
                </>
            )
        },
        {
            field: 'createdAt',
            isSort: true,
            headerName: 'Created Date',
            width: '20%',
            render: (row) => <>{moment(row?.createdAt).format('DD/MM/YYYY')}</>
        },
        {
            field: 'actions',
            isSort: false,
            headerName: 'Action',
            render: (row) => (
                <>
                    <Stack direction="row">
                        <Tooltip title="Edit" placement="top">
                            <Button
                                variant="outlined"
                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                onClick={() => handleShowEdit(row)}
                            >
                                <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="Remove" placement="top">
                            <Button
                                variant="outlined"
                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                onClick={() => handleShowDelete(row)}
                                disabled={row?.nameBranch === 'KWSH' || row?.nameBranch === 'LFP'}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                            </Button>
                        </Tooltip>
                    </Stack>
                </>
            )
        }
    ];

    React.useEffect(() => {
        debounceHandleGetUserList();
    }, [filter]);

    const handleGetBrandList = async () => {
        const { dataList, meta } = await brandApi.listBrand({
            ...filter
        });
        if (dataList) setBrandList(dataList);
        if (meta) setMetaData(meta);
    };

    const debounceHandleGetUserList = useDebounce(handleGetBrandList, 300);

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, page: 1, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const handleShowEdit = (brand: any) => {
        UIManager.showModal({
            content: (onClose: any) => <BrandForm onClose={onClose} callback={handleGetBrandList} brandEdit={brand} />,
            stylesModal: {
                minWidth: '500px'
            }
        });
    };

    const handleShowDelete = (brand: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteBrandModal onClose={onClose} callback={handleGetBrandList} brandEdit={brand} />
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item sx={{ width: '30%' }}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by Brand name or Center name"
                            value={filter.query}
                            size="small"
                            style={{ marginRight: '8px', width: '100%' }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                UIManager.showModal({
                                    content: (onClose: any) => <BrandForm onClose={onClose} callback={handleGetBrandList} />,
                                    stylesModal: {
                                        minWidth: '500px'
                                    }
                                });
                            }}
                            style={{ color: 'white', backgroundColor: '#3F51B5' }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {brandList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={brandList}
                            columns={cols}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                    </Box>

                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <EmptyData />
                        <br />
                        {filter.query !== '' && <div>Sorry we couldn't find any matches for {filter.query}</div>}
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default BrandList;

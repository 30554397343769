import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebounce } from 'services/hooks/useDebounce';

interface ICustomSelect extends SelectProps {
    onChange: (e: any) => void;
    value: string | string[] | undefined | null;
    data: Object[];
    defaultValue?: string | string[];
    selectProps?: SelectProps;
    disabled?: boolean;
    multiple?: boolean;
    name: string;
    label: string;
    size?: 'small' | 'medium';
    componentSx?: Object;
}

const noData = 'no-data';

// This will display empty select when multiple is true and data is empty
// Need improvement
const CustomSelectCenter = ({
    onChange,
    data,
    value,
    defaultValue,
    disabled = false,
    multiple = false,
    name,
    label,
    selectProps,
    size = 'small',
    componentSx
}: ICustomSelect) => {
    const [selectedCenterIds, setSelectedCenterIds] = useState<any>('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isMultiple, setIsMultiple] = useState(false);

    useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    useEffect(() => {
        if (multiple) setSelectedCenterIds(value);

        debounceHandleSetMultiple();
    }, [multiple]);

    useEffect(() => {
        debounceCheckEmpty();
    }, [value, data]);

    const handleSetMultiple = () => {
        setIsMultiple(multiple);
    };

    const debounceHandleSetMultiple = useDebounce(handleSetMultiple, 100);

    const hasData = (): boolean => {
        return data && data.length > 0;
    };

    const checkEmpty = () => {
        if (multiple && !hasData()) {
            setSelectedCenterIds([]);
            setIsDisabled(true);
        } else if (!hasData()) {
            setSelectedCenterIds(noData);
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
            setSelectedCenterIds(value);
        }
    };

    const debounceCheckEmpty = useDebounce(checkEmpty, 150);

    const handleOnChange = (e: any) => {
        onChange && onChange(e);
    };

    return (
        <FormControl sx={{ minWidth: 280, maxWidth: 360, ...componentSx }}>
            <InputLabel size={size === 'medium' ? 'normal' : 'small'} id={`${name}-label`}>
                {label}
            </InputLabel>
            <Select
                labelId={`${name}-label`}
                disabled={isDisabled}
                id={name}
                name={name}
                label={label}
                defaultValue={defaultValue || noData}
                value={selectedCenterIds || noData}
                onChange={handleOnChange}
                size={size}
                multiple={isMultiple}
                {...selectProps}
            >
                {hasData() ? (
                    data?.map((center: any) => (
                        <MenuItem key={center.id} value={center.id}>
                            {center.nameCenter}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value={noData}>No data</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default CustomSelectCenter;

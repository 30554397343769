import { useEffect, useState } from 'react';
// project imports

// assets

import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project imports
import ModalCustom from 'components/Modal/ModalCustom';
import { useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi } from 'services/api';
import CustomSelectCenter from 'components/CustomSelectCenter';
import brandApi from 'services/api/brandApi';
import { styled } from '@mui/material/styles';

const TextError = styled('div')({
    width: '100%',
    '& .MuiFormHelperText-root': {
        marginLeft: '0px'
    }
});

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    brandEdit?: any;
}

const BrandForm = (props: IProps) => {
    const classes = useStyles();
    const { brandEdit, callback, onClose } = props;
    const isCheckNameDisable = brandEdit?.nameBranch === 'KWSH' || brandEdit?.nameBranch === 'LFP';
    const centerId = brandEdit
        ? brandEdit?.center?.map((el: any) => {
              return el?.id;
          })
        : [];
    const [centerList, setCenterList] = useState<any>([]);
    useEffect(() => {
        handleGetCenterList();
    }, []);

    const handleSubmitBrand = async (obj: any, config: any) => {
        try {
            const nameOld = brandEdit?.nameBranch;
            const payload = {
                name: obj.name,
                centerId: selectedNames
            };
            if (nameOld === obj.name) {
                delete payload.name;
            }
            if (brandEdit) {
                const id = brandEdit.id;

                const { data, error } = await brandApi.updateBrand(id, payload);
                if (error && error?.response?.status === 400) {
                    const messError = error?.response?.data?.details?.name;
                    messError.map((err: any) => {
                        return UIManager.showAlert({
                            message: err,
                            alert: {
                                color: 'error'
                            }
                        });
                    });

                    return;
                }
                if (data) {
                    UIManager.showAlert({
                        message: 'Update successfully!',
                        alert: {
                            color: 'success'
                        }
                    });
                    callback();
                    onClose();
                }
            } else {
                const { data, error } = await brandApi.createBrand(payload);
                if (error && error?.response?.status === 400) {
                    const messError = error?.response?.data?.details?.name;
                    messError.map((err: any) => {
                        return UIManager.showAlert({
                            message: err,
                            alert: {
                                color: 'error'
                            }
                        });
                    });

                    return;
                }
                if (data) {
                    UIManager.showAlert({
                        message: 'Create successfully!',
                        alert: {
                            color: 'success'
                        }
                    });
                    callback();
                    onClose();
                }
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };
    const handleGetCenterList = async () => {
        const { dataList } = await CentreApi.listCentre();
        if (dataList) {
            setCenterList(dataList);
        }
    };

    const [selectedNames, setSelectedNames] = useState(centerId);
    return (
        <div style={{ width: 600 }}>
            <Formik
                initialValues={{
                    name: brandEdit?.nameBranch || '',
                    centerId: selectedNames
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Brand name is required'),
                    centerId: Yup.array().min(1, 'Please select at least 1 center').required('Center is required')
                })}
                onSubmit={handleSubmitBrand}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm, setFieldValue }) => (
                    <ModalCustom
                        onSave={submitForm}
                        disabled={isSubmitting}
                        onClose={onClose}
                        onCancel={onClose}
                        title={!brandEdit ? 'Create a Brand' : 'Edit a Brand'}
                        contentSave={!brandEdit ? 'Create' : 'Save'}
                        contentCancel="Back"
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid
                                container
                                spacing={2}
                                columns={16}
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                <Grid item xs={16} sm={16}>
                                    <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Brand Name"
                                            name="name"
                                            type="text"
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setFieldValue('name', val.trim());
                                                handleChange(e);
                                            }}
                                            variant="outlined"
                                            inputProps={{}}
                                            disabled={isCheckNameDisable}
                                        />
                                        {touched.name && errors.name && (
                                            <TextError>
                                                <FormHelperText error id="standard-weight-helper-text--register">
                                                    {errors.name}
                                                </FormHelperText>
                                            </TextError>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl fullWidth error={Boolean(touched.centerId && errors.centerId)}>
                                <CustomSelectCenter
                                    componentSx={{
                                        width: '100%',
                                        maxWidth: 'unset',

                                        '.MuiSelect-multiple': {
                                            lineHeight: '1.1375em',
                                            height: 'auto',
                                            marginTop: '8px'
                                        },
                                        '.MuiInputLabel-root': {
                                            top: '3px'
                                        }
                                    }}
                                    data={centerList}
                                    name="centerId"
                                    label="Centers"
                                    size="medium"
                                    value={selectedNames}
                                    onChange={(e: any) => {
                                        setSelectedNames(e.target.value);
                                        handleChange(e);
                                    }}
                                    multiple
                                    selectProps={{
                                        MenuProps: {
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }
                                    }}
                                />
                                {touched.centerId && errors.centerId && (
                                    <TextError>
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.centerId}
                                        </FormHelperText>
                                    </TextError>
                                )}
                            </FormControl>
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </div>
    );
};

export default BrandForm;

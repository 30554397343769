import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';
import ClusterList from 'modules/main/cluster/ClusterList';
import UserList from 'modules/main/user/UserList';
import BrandList from 'modules/main/brand/BrandList';
import RatioList from '../RatioInfo';
import AbsenceList from '../AbsenceList';
import HricList from '../HricList';
import QualificationList from '../QualificationList';

import './styles.scss';
import { AUTH_LOCAL_STORAGE_KEY, RedirectPath, Roles } from 'config';
import { useNavigate } from 'react-router-dom';

interface ReliefManagementTabsProps {
    tab?: string;
}
const SettingTabs = ({ tab }: ReliefManagementTabsProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const path = window.location.pathname;
    const navigate = useNavigate();
    const [value, setValue] = React.useState(tab || '1');

    useEffect(() => {
        if (path.includes(RedirectPath.USER_MANAGEMENT)) {
            setValue('2');
        } else if (path.includes(RedirectPath.BRAND_MANAGEMENT)) {
            setValue('7');
        } else if (path.includes(RedirectPath.RATIO_LIST)) {
            setValue('3');
        } else if (path.includes(RedirectPath.ABSENCE_LIST)) {
            setValue('4');
        } else if (path.includes(RedirectPath.HRIC_MANAGEMENT)) {
            setValue('5');
        } else if (path.includes(RedirectPath.QUALIFICATION_LIST)) {
            setValue('6');
        } else {
            setValue('1');
        }
    }, [path]);
    const handleChangeTabRelief = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            navigate(RedirectPath.CLUSTER_MANAGEMENT);
        } else if (newValue === '2') {
            navigate(RedirectPath.USER_MANAGEMENT);
        } else if (newValue === '7') {
            navigate(RedirectPath.BRAND_MANAGEMENT);
        } else if (newValue === '3') {
            navigate(RedirectPath.RATIO_LIST);
        } else if (newValue === '4') {
            navigate(RedirectPath.ABSENCE_LIST);
        } else if (newValue === '5') {
            navigate(RedirectPath.HRIC_MANAGEMENT);
        } else if (newValue === '6') {
            navigate(RedirectPath.QUALIFICATION_LIST);
        }
    };

    const isShowTabCluster = () => {
        return userRole === Roles.Admin;
    };
    const isShowTabUser = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };
    const isShowTabRatio = () => {
        return true;
    };
    const isShowTabAbsence = () => {
        return userRole === Roles.Admin || userRole === Roles.Cluster || userRole === Roles.Principals;
    };
    const isShowTabHRIC = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };
    const isShowTabQualification = () => {
        return userRole === Roles.Admin || userRole === Roles.Cluster || userRole === Roles.Principals;
    };
    return (
        <div className="setting-tab-management">
            <TabContext value={value}>
                <TabList onChange={handleChangeTabRelief} className="setting-tab-list">
                    {isShowTabCluster() && <Tab label="Cluster Management" value="1" />}
                    {isShowTabCluster() && <Tab label="Brand Management" value="7" />}
                    {isShowTabUser() && <Tab label="User Management" value="2" />}
                    {isShowTabRatio() && <Tab label="List Ratio" value="3" />}
                    {isShowTabAbsence() && <Tab label="Absence" value="4" />}
                    {isShowTabHRIC() && <Tab label="HR-IC Management" value="5" />}
                    {isShowTabQualification() && <Tab label="Qualification" value="6" />}
                </TabList>
                <TabPanel value="1" className="setting-manage-panel cluster-panel">
                    <ClusterList />
                </TabPanel>
                <TabPanel value="7" className="setting-manage-panel brand-panel">
                    <BrandList />
                </TabPanel>
                <TabPanel value="2" className="setting-manage-panel user-panel">
                    <UserList />
                </TabPanel>
                <TabPanel value="3" className="setting-manage-panel ratio-panel">
                    <RatioList />
                </TabPanel>
                <TabPanel value="4" className="setting-manage-panel absence-panel">
                    <AbsenceList />
                </TabPanel>
                <TabPanel value="5" className="setting-manage-panel hric-panel">
                    <HricList />
                </TabPanel>
                <TabPanel value="6" className="setting-manage-panel qualification-panel">
                    <QualificationList />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default SettingTabs;

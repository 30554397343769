/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    Button,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TablePagination,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { IconDownload } from '@tabler/icons';
import ArrowDownIcon from 'assets/images/pages/arrow-down.svg';
import resetIcon from 'assets/images/pages/reset-icon.svg';
import EmptyData from 'components/errors/empty-data';
import WarningIcon from 'assets/images/icons/tabler-icon-warning.svg';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles, SIZING_CLASS_TYPE, formatDate, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CentreApi, clusterApi } from 'services/api';
import { CentreKecho } from 'types/kecho/centre-kecho';
import ExportSizingModal from './components/ExportSizingModal';
import SizingTable from './components/SizingTable';
import SizingTableClassBreakdown from './components/SizingTableClassBreakdown';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import CustomSelectCenterClassBreakdown from 'components/CustomSelectCenter';
import CustomSelectCenter from './components/CustomSelectCenter';
import CustomSelectBrand from './components/CustomSelectBrand';
import CustomSelectCluster from './components/CustomSelectCluster';
import { debounce } from 'lodash';
import brandApi from 'services/api/brandApi';

interface IFilter {
    centerIds: string[];
    fromDate: string | null;
    toDate: string | null;
    class: string;
    page: number;
    take: number;
}

function Sizing() {
    const classes = useStyles();
    const [isOpenCenterSelect, setIsOpenCenterSelect] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;

    const [sizingData, setSizingData] = useState<any>([]);
    const [centerRatioNotFData, setCenterRatioNotFData] = useState<any>([]);
    const [metaData, setMetaData] = useState<any>([]);
    const [clusterData, setClusterList] = useState<any>([]);
    const [brandData, setBrandList] = useState<any>([]);
    const [messageError, setMessageError] = useState<string>('');
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const [selectOption, setSelectOption] = useState<string>('All centers');
    const [filterRanger, setFilterRanger] = useState({
        first: 'this',
        last: 'day',
        number: 1,
        date: moment().format(formatDate.DATE_DEFAULT) || null,
        fromDate: moment().format(formatDate.DATE_DEFAULT) || null,
        toDate: moment().format(formatDate.DATE_DEFAULT) || null
    });
    // filter params
    const [filter, setFilter] = useState<IFilter>({
        centerIds: [],
        fromDate: moment().format(formatDate.DATE_DEFAULT) || null,
        toDate: moment().format(formatDate.DATE_DEFAULT) || null,
        class: 'overall',
        page: 1,
        take: 10
    });
    const [selectedClusterId, setSelectedClusterId] = useState<string>('');
    const [selectedBrandId, setSelectedBrandId] = useState<string>('');

    useEffect(() => {
        handleGetCenterList();
    }, []);

    useEffect(() => {
        const debouncedGetData = debounce(() => {
            if (filter.centerIds?.length > 0 || userCenterID) {
                getSizingData();
            }
        }, 200);

        debouncedGetData();
        return () => debouncedGetData.cancel();
    }, [filter]);

    useEffect(() => {
        // Gọi getSizingData khi selectedClusterId thay đổi
        if (selectOption === 'Cluster' && selectedClusterId) {
            getSizingData();
        }
    }, [selectedClusterId, selectOption]);
    useEffect(() => {
        // Gọi getSizingData khi selectedBrandId thay đổi
        if (selectOption === 'Brand' && selectedBrandId) {
            getSizingData();
        }
    }, [selectedBrandId, selectOption]);

    const handleGetCenterList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            setFilter((prev) => ({ ...prev, centerIds: [dataList[0]?.id] }));
        }
    };

    const handleGetClusterList = async () => {
        const { dataList } = await clusterApi.listCluster();
        if (dataList) {
            setClusterList(dataList);
            setSelectedClusterId(dataList[0].id);
        }
    };

    const handleGetBrandList = async () => {
        const { dataList } = await brandApi.listBrand();
        if (dataList) {
            setBrandList(dataList);
            setSelectedBrandId(dataList[0].id);
        }
    };

    const getSizingData = async () => {
        try {
            if (messageError || !Object.values(filter).every((filterValue) => !!filterValue)) {
                setSizingData([]);
                setMetaData([]);
                setCenterRatioNotFData([]);
                return;
            }

            if (
                (filterRanger.last === 'month' && (filterRanger.number > 3 || filterRanger.number <= 0)) ||
                (filterRanger.last === 'week' && (filterRanger.number > 12 || filterRanger.number <= 0)) ||
                (filterRanger.last === 'day' && (filterRanger.number > 90 || filterRanger.number <= 0))
            ) {
                setSizingData([]);
                setMetaData([]);
                setCenterRatioNotFData([]);
                return;
            }

            const centerId = userCenterID || filter.centerIds[0];
            const param = {
                fromDate: filter?.fromDate || moment().format(formatDate.DATE_DEFAULT),
                toDate: filter?.toDate || moment().format(formatDate.DATE_DEFAULT),
                page: filter?.page || 1,
                take: filter?.take || 10
            };
            // Build payload based on selected category
            let payload = null;
            if (filter.class === SIZING_CLASS_TYPE.OVERALL) {
                switch (selectOption) {
                    case 'All centers':
                        payload = {
                            isAllCenter: true
                        };
                        break;
                    case 'Cluster':
                        if (selectedClusterId) {
                            payload = {
                                clusterId: selectedClusterId
                            };
                        } else {
                            return;
                        }
                        break;
                    case 'Brand':
                        if (selectedBrandId) {
                            payload = {
                                brandId: selectedBrandId
                            };
                        } else {
                            return;
                        }
                        break;
                    case 'Select centers':
                        payload = {
                            centerIds: filter.centerIds
                        };
                        break;
                    default:
                        break;
                }
            }
            setIsLoading(true);

            const { data, meta, error } = await CentreApi.listSizingOfCenter(
                userRole,
                centerId,
                filter.centerIds,
                filter.class,
                param,
                payload
            );

            if (error) {
                setSizingData([]);
                setCenterRatioNotFData([]);
                setIsLoading(true);
                if (error && error?.response?.status === 400) {
                    const messError = error?.response?.data?.details;
                    UIManager.showAlert({
                        message: messError,
                        alert: {
                            color: 'error'
                        }
                    });
                }
                return;
            }

            if (data) {
                setSizingData(data?.data);
                setCenterRatioNotFData(data?.centerRatiosNotF);
            }
            if (meta) setMetaData(meta);
            setIsLoading(false);
        } catch (err: any) {
            setSizingData([]);
            setCenterRatioNotFData([]);
            setMetaData(null);
            setMessageError(err.message);
        }
    };

    const setDefaultDateRange = () => {
        setMessageError('');
        setFilterRanger((prev) => {
            return {
                ...prev,
                number: 1,
                date: moment().format(formatDate.DATE_DEFAULT),
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
            };
        });
        setFilter((prev) => ({
            ...prev,
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
        }));
    };

    // date filter next/past {number} day
    const isSoFilterDayDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'days').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().subtract(number, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'days').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} week
    const isSoFilterWeekDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).add(1, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).add(number, 'weeks').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).subtract(number, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).subtract(1, 'weeks').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} month
    const isSoFilterMonthDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().add(1, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'months').endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().subtract(number, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'months').endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    const validateFilter = (first: string, number: number, last: string) => {
        if (first === 'select-date' || first === 'range-date') {
            setMessageError('');
            return true;
        }
        if (first === 'this' || first === 'next' || first === 'past') {
            if (last === 'day') {
                if (number > 0 && number <= 90) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 90 of day');
                return true;
            }
            if (last === 'week') {
                if (number > 0 && number <= 12) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 12 of week');
                return true;
            }
            if (last === 'month') {
                if (number > 0 && number <= 3) {
                    setMessageError('');
                    return true;
                }
                setMessageError('Please enter a number between 1 and 3 of month');
                return true;
            }
        }

        return false;
    };

    const handleChangeFirst = (e: any) => {
        const first = e.target.value;
        if (!validateFilter(first, filterRanger.number, filterRanger.last)) {
            return;
        }
        const rangerNumber = 1;
        setMessageError('');
        setFilterRanger((prev) => {
            return {
                ...prev,
                date: moment().format(formatDate.DATE_DEFAULT),
                number: 1,
                first
            };
        });

        if (first === 'this') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
            // updateFilterWithDate(moment().format(formatDate.DATE_DEFAULT));
        } else if (first === 'next') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
        } else if (first === 'past') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
        } else if (first === 'select-date') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().format(formatDate.DATE_DEFAULT)
            }));
        } else if (first === 'range-date') {
            setDefaultDateRange();
        }
    };

    const handleChangeInputNumber = (e: any) => {
        let number = e.target.value;

        // Loại bỏ các ký tự không phải số
        number = number.replace(/\D/g, '');
        if (number === '') {
            number = 0;
            return;
        }

        // Nếu giá trị bắt đầu bằng "0" và có thêm số khác, loại bỏ "0"
        if (number.length > 1 && number.startsWith('0')) {
            number = number.slice(1);
        }

        if (!validateFilter(filterRanger.first, number, filterRanger.last)) {
            return;
        }
        setFilterRanger((prev) => ({ ...prev, number }));

        if (filterRanger.first === 'next') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate('next', number);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate('next', number);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate('next', number);
            }
        } else if (filterRanger.first === 'past') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate('past', number);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate('past', number);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate('past', number);
            }
        }
    };

    const handleChangeLast = (e: any) => {
        const last = e.target.value;
        if (!validateFilter(filterRanger.first, filterRanger.number, last)) {
            return;
        }
        setFilterRanger((prev) => ({ ...prev, last }));
        if (last === 'day') {
            if (filterRanger.first === 'next') {
                isSoFilterDayDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterDayDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterDayDate('this');
            }
        } else if (last === 'week') {
            if (filterRanger.first === 'next') {
                isSoFilterWeekDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterWeekDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterWeekDate('this');
            }
        } else if (last === 'month') {
            if (filterRanger.first === 'next') {
                isSoFilterMonthDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterMonthDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterMonthDate('this');
            }
        }
    };

    const handleOnChangeDateRange = (value: any) => {
        const fromDate = new Date(value.fromDate);
        const toDate = new Date(value.toDate);
        const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.ceil(diffDays / 30);

        if (diffMonths > 3) {
            setMessageError('Please select a range within 90 days before or after today');
            return;
        }

        setFilterRanger((prev) => ({ ...prev, ...value }));
        setFilter((prev) => ({ ...prev, fromDate: value.fromDate, toDate: value.toDate, page: 1 }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const handleShowExport = () => {
        UIManager.showModal({
            content: (onClose: any) => <ExportSizingModal onClose={onClose} />,
            stylesModal: {
                maxWidth: 1200
            }
        });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin || userRole === Roles.HrAdmin || userRole === Roles.HrIc;
    };

    const isShowClusterCategory = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const handleChangeCategory = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectOption(selectedValue);

        setFilter((prev) => ({
            ...prev,
            centerIds: selectedValue === 'Select centers' ? [] : prev.centerIds
        }));
        // Set states based on selected category
        switch (selectedValue) {
            case 'All centers':
                break;

            case 'Cluster':
                handleGetClusterList();
                break;

            case 'Brand':
                handleGetBrandList();
                break;

            case 'Select centers':
                // Keep existing centerIds
                setFilter((prev) => ({
                    ...prev,
                    centerIds: filter.centerIds
                }));
                break;

            default:
                break;
        }
    };

    const handleOnchangeCenter = (e: any) => {
        setFilter((prev) => ({
            ...prev,
            centerIds: e.target.value
        }));
    };

    const handleOnchangeBrand = (e: any) => {
        const value = e.target.value;
        setSelectedBrandId(value);
    };

    const handleOnchangeCluster = (e: any) => {
        const value = e.target.value;
        setSelectedClusterId(value);
    };

    const handleResetToDefaultFilter = () => {
        setMessageError('');
        setFilterRanger({
            first: 'this',
            last: 'day',
            number: 1,
            date: moment().format(formatDate.DATE_DEFAULT),
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().format(formatDate.DATE_DEFAULT)
        });
        setFilter((prev) => ({
            ...prev,
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().format(formatDate.DATE_DEFAULT),
            page: 1
        }));
    };

    const filteredCenterNames = useMemo(() => {
        const lessCenterIds = [...filter.centerIds].splice(1);
        const fullCenterIds = [...filter.centerIds];
        if (filter.centerIds.length === centreData.length) {
            return centreData?.filter((item) => fullCenterIds.includes(item.id as string)).map((item) => item.nameCenter);
        }
        return centreData?.filter((item) => lessCenterIds.includes(item.id as string)).map((item) => item.nameCenter);
    }, [centreData, filter.centerIds]);
    const TooltipContent = ({ names }: { names: any[] }) => (
        <div>
            {names.map((name, index) => (
                <div key={index}>{name}</div>
            ))}
        </div>
    );
    const CustomIconComponent = () => {
        const isSumCenter = filter.centerIds.length === centreData.length;
        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
                style={{
                    right: '10px',
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: 'none',
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenCenterSelect(true);
                }}
            >
                {filter.centerIds?.length > 1 && (
                    <Tooltip title={<TooltipContent names={filteredCenterNames} />} arrow>
                        <div style={{ minWidth: '65px', display: 'flex', marginRight: '6px', fontSize: '12px', fontWeight: 600 }}>
                            {isSumCenter ? `+ ${filter.centerIds?.length} more` : `+ ${filter.centerIds?.length - 1} more`}
                        </div>
                    </Tooltip>
                )}

                <img src={ArrowDownIcon} alt="&darr;" />
            </div>
        );
    };

    const centerNamesNotFound = useMemo(() => {
        return centerRatioNotFData.map((center: string) => center);
    }, [centerRatioNotFData]);
    const TooltipCenterNotFound = ({ names }: { names: string[] }) => (
        <div>
            <span>The following Centers' sizing data is not available:</span>
            {names.map((name, index) => (
                <div>
                    <ul>
                        <li key={index}>{name}</li>
                    </ul>
                </div>
            ))}
        </div>
    );

    const handleCenterSelectClick = (e: any) => {
        const element = e.target as HTMLElement;
        // Check when clicking outside the select box

        if (element.childNodes.length <= 0 || (element.childNodes.length === 4 && element.nodeName === 'DIV')) {
            setIsOpenCenterSelect(false);
            return;
        }

        if (isOpenCenterSelect && filter.class === SIZING_CLASS_TYPE.OVERALL) {
            return;
        }

        setIsOpenCenterSelect(true);
    };

    const handleDatePickerChange = (e: any) => {
        const currentDate = moment().format(formatDate.DATE_DEFAULT);
        const date = e ? moment(e).format(formatDate.DATE_DEFAULT) : '';
        const fromDate = new Date(currentDate);
        const toDate = new Date(date);
        const diffTime = Math.abs(toDate.getTime() - fromDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffMonths = Math.ceil(diffDays / 30);
        if (diffMonths > 3) {
            setMessageError('Please select a range within 90 days before or after today');
            return;
        }
        setMessageError('');
        setFilterRanger((prev) => ({ ...prev, date }));
        setFilter((prev) => ({
            ...prev,
            fromDate: date,
            toDate: date,
            page: 1
        }));
    };
    const handleDateError = () => {
        setMessageError('');
        setSizingData([]);
        setMetaData([]);
    };
    return (
        <div className="sizing-wrapper">
            <CardContent className="sizing-top-filter">
                <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                    <Grid xs={12} sx={{ marginTop: 0, marginLeft: 0, width: '100%' }}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            {/* Class Selection */}
                            <FormControl variant="outlined">
                                <InputLabel size="small" id="sizing-page__select-class__label">
                                    Class
                                </InputLabel>
                                <Select
                                    disabled={!(centreData?.length > 0 || userCenterID)}
                                    labelId="sizing-page__select-class"
                                    id="sizing-page__select-class_select"
                                    name="class"
                                    label="Class"
                                    value={filter.class}
                                    size="small"
                                    sx={{ minWidth: 100 }}
                                    onChange={(e) => {
                                        setFilter((prev) => ({ ...prev, class: e.target.value }));
                                    }}
                                >
                                    <MenuItem value={SIZING_CLASS_TYPE.OVERALL}>Overall</MenuItem>
                                    <MenuItem value={SIZING_CLASS_TYPE.CLASS_BREAKDOWN}>Class Breakdown</MenuItem>
                                </Select>
                            </FormControl>

                            {/* Category Selection for Overall Class */}
                            {filter.class === SIZING_CLASS_TYPE.OVERALL && (
                                <>
                                    {isShowCenterFilter() && (
                                        <FormControl variant="outlined" sx={{ marginLeft: '10px' }}>
                                            <InputLabel size="small" id="sizing-page__select-category__label">
                                                Category
                                            </InputLabel>
                                            <Select
                                                labelId="sizing-page__select-category"
                                                id="sizing-page__select-category_select"
                                                name="category"
                                                label="Category"
                                                value={selectOption}
                                                size="small"
                                                sx={{ minWidth: 100 }}
                                                onChange={handleChangeCategory}
                                            >
                                                <MenuItem value="All centers">All centers</MenuItem>
                                                {isShowClusterCategory() && <MenuItem value="Cluster">Cluster</MenuItem>}
                                                <MenuItem value="Brand">Brand</MenuItem>
                                                <MenuItem value="Select centers">Select centers</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}

                                    {/* Brand Selection */}
                                    {selectOption === 'Brand' && (
                                        <CustomSelectBrand
                                            data={brandData}
                                            componentSx={{ marginLeft: '10px' }}
                                            name="brandIds"
                                            label="Brand"
                                            onChange={handleOnchangeBrand}
                                            defaultValue={selectedBrandId}
                                            value={selectedBrandId}
                                            selectProps={{
                                                MenuProps: { classes: { paper: classes.selectMenu } }
                                            }}
                                        />
                                    )}

                                    {/* Cluster Selection */}
                                    {selectOption === 'Cluster' && (
                                        <CustomSelectCluster
                                            data={clusterData}
                                            componentSx={{ marginLeft: '10px' }}
                                            name="clusterId"
                                            label="Cluster"
                                            onChange={handleOnchangeCluster}
                                            defaultValue={selectedClusterId}
                                            value={selectedClusterId}
                                            selectProps={{
                                                MenuProps: { classes: { paper: classes.selectMenu } }
                                            }}
                                        />
                                    )}

                                    {/* Center Selection */}
                                    {isShowCenterFilter() && selectOption === 'Select centers' && (
                                        <CustomSelectCenter
                                            data={centreData}
                                            componentSx={{
                                                marginLeft: '10px',
                                                '& .MuiSelect-select': {
                                                    ...(filter.centerIds.length > 1 && {
                                                        paddingRight: '20px !important'
                                                    })
                                                }
                                            }}
                                            name="centerIds"
                                            label="Center"
                                            onChange={handleOnchangeCenter}
                                            value={filter.centerIds}
                                            multiple
                                            selectProps={{
                                                IconComponent: CustomIconComponent,
                                                open: isOpenCenterSelect,
                                                onClick: handleCenterSelectClick,
                                                MenuProps: { classes: { paper: classes.selectMenu } }
                                            }}
                                        />
                                    )}
                                </>
                            )}

                            {/* Center Selection for Class Breakdown */}
                            {filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN && isShowCenterFilter() && (
                                <CustomSelectCenterClassBreakdown
                                    data={centreData}
                                    componentSx={{
                                        marginLeft: '10px',
                                        '& .MuiSelect-select': {
                                            ...(filter.centerIds.length > 1 && {
                                                paddingRight: '20px !important'
                                            })
                                        }
                                    }}
                                    name="centerIds"
                                    label="Center"
                                    onChange={handleOnchangeCenter}
                                    value={filter.centerIds}
                                    multiple
                                    selectProps={{
                                        IconComponent: CustomIconComponent,
                                        open: isOpenCenterSelect,
                                        onClick: handleCenterSelectClick,
                                        MenuProps: { classes: { paper: classes.selectMenu } }
                                    }}
                                />
                            )}

                            {/* Date Filter Controls */}
                            <FormControl style={{ width: 130, marginLeft: 10 }}>
                                <InputLabel id="first-select-label">First</InputLabel>
                                <Select
                                    disabled={!(centreData?.length > 0 || userCenterID)}
                                    labelId="first-select-label"
                                    id="first-select"
                                    name="isFirst"
                                    value={filterRanger.first}
                                    label="Filter"
                                    size="small"
                                    onChange={handleChangeFirst}
                                >
                                    <MenuItem value="this">This</MenuItem>
                                    <MenuItem value="next">Next</MenuItem>
                                    <MenuItem value="past">Past</MenuItem>
                                    <MenuItem value="select-date">Select date</MenuItem>
                                    <MenuItem value="range-date">Date range</MenuItem>
                                </Select>
                            </FormControl>

                            <div className="date-filter" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                {/* Number Input for Next/Past */}
                                {(filterRanger.first === 'next' || filterRanger.first === 'past') && (
                                    <FormControl style={{ width: 70, marginLeft: 10 }}>
                                        <TextField
                                            type="number"
                                            className="input-number"
                                            size="small"
                                            label="Number"
                                            value={filterRanger.number}
                                            onChange={handleChangeInputNumber}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                )}

                                {/* Last Selection */}
                                {(filterRanger.first === 'this' || filterRanger.first === 'next' || filterRanger.first === 'past') && (
                                    <FormControl style={{ width: 100, marginLeft: 10 }}>
                                        <InputLabel id="last-select-label">Last</InputLabel>
                                        <Select
                                            disabled={!(centreData?.length > 0 || userCenterID)}
                                            labelId="last-select-label"
                                            id="last-select"
                                            name="isLast"
                                            value={filterRanger.last}
                                            label="Filter"
                                            onChange={handleChangeLast}
                                            size="small"
                                        >
                                            <MenuItem value="day">Day</MenuItem>
                                            <MenuItem value="week">Week</MenuItem>
                                            <MenuItem value="month">Month</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                                {/* Date Picker */}
                                {filterRanger.first === 'select-date' && (
                                    <LocalDesktopDatePicker
                                        label="Select Date"
                                        value={filterRanger.date}
                                        sx={{ marginLeft: '10px', width: '165px', maxWidth: 'unset' }}
                                        isRequired
                                        onChange={handleDatePickerChange}
                                        onError={handleDateError}
                                    />
                                )}

                                {/* Date Range Picker */}
                                {filterRanger.first === 'range-date' && (
                                    <DateRangeLocalDesktopDatePicker
                                        isRequired
                                        views={['year', 'month', 'day']}
                                        sx={{ marginLeft: '10px' }}
                                        dateSx={{ width: '165px', maxWidth: 'unset' }}
                                        fromDateValue={filterRanger.fromDate}
                                        toDateValue={filterRanger.toDate}
                                        onChange={handleOnChangeDateRange}
                                        onError={handleDateError}
                                    />
                                )}

                                {/* Error Message */}
                                {messageError && (
                                    <div className="error-message" style={{ position: 'absolute', top: 40, left: 18 }}>
                                        <Typography variant="body2" color="error" style={{ whiteSpace: 'nowrap' }}>
                                            {messageError}
                                        </Typography>
                                    </div>
                                )}
                            </div>

                            {/* Reset Button */}
                            {(centreData.length > 0 || userCenterID) && (
                                <FormControl style={{ marginLeft: 10, marginRight: 10 }}>
                                    <Button
                                        size="medium"
                                        style={{
                                            color: '#3F51B5',
                                            padding: '12px',
                                            border: '1px solid #687FD1',
                                            whiteSpace: 'nowrap'
                                        }}
                                        onClick={handleResetToDefaultFilter}
                                    >
                                        <img style={{ marginRight: '8px' }} src={resetIcon} alt="reset-icon" /> Reset
                                    </Button>
                                </FormControl>
                            )}
                            {centerRatioNotFData?.length > 0 && (
                                <Tooltip title={<TooltipCenterNotFound names={centerNamesNotFound} />} arrow>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={WarningIcon}
                                            alt="warning"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginRight: 5,
                                                marginTop: '-3px'
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </Grid>

                    {/* Export Button */}
                    {(centreData.length > 0 || userCenterID) && (
                        <Grid container justifyContent="flex-end">
                            <div>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    style={{ backgroundColor: '#3F51B5', outline: 'none', color: 'white' }}
                                    onClick={handleShowExport}
                                >
                                    <IconDownload style={{ width: 22, marginRight: 8 }} /> Export sizing data
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </CardContent>

            {/* Content Section */}
            <CardContent className="sizing-content">
                {sizingData && sizingData.length > 0 ? (
                    sizingData?.map((item: any, index: number) =>
                        filter.class === 'overall' ? (
                            <SizingTable key={index} data={item} isLoading={isLoading} />
                        ) : (
                            <SizingTableClassBreakdown key={index} data={item} isLoading={isLoading} />
                        )
                    )
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}

                {/* Pagination */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={metaData?.itemCount || 0}
                    rowsPerPage={filter?.take || 10}
                    page={filter?.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CardContent>
        </div>
    );
}

export default Sizing;

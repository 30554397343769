import { addRoutes } from 'store/slices/kecho/core/system';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import ReliefManagementTabs from './ReliefManagementTabs';
import StaffPlanningTabs from './StaffPlanningTabs';
// import ReliefList from './Relief';
// import ReliefTicket from './ReliefTicket';
// import TimesheetApproval from './TimesheetApproval';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import Attendance from './Attendance';
import SettingTabs from './SettingTabs';

// const CalculationStaffRatio = Loadable(lazy(() => import('./CalculationStaffRatio/List')));
// const StaffList = Loadable(lazy(() => import('./StaffList')));
// const ClassList = Loadable(lazy(() => import('modules/main/class/ClassList')));
const PlanStaff = Loadable(lazy(() => import('./PlanStaff')));
const StaffDetails = Loadable(lazy(() => import('./StaffDetails')));
// const RatioInfo = Loadable(lazy(() => import('./RatioInfo')));
// const AbsenceList = Loadable(lazy(() => import('./AbsenceList')));
// const QualificationList = Loadable(lazy(() => import('./QualificationList')));
// const HricList = Loadable(lazy(() => import('./HricList')));

class StaffModule {
    constructor() {
        addRoutes([
            {
                path: '/staff/staff-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <StaffPlanningTabs tab="1" />
                        {/* <StaffList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/staff-details/:id',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <StaffDetails />
                    </AuthGuard>
                )
            },
            {
                path: '/staff/plan-staff/:id/:centerId',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <PlanStaff />
                    </AuthGuard>
                )
            },
            {
                path: '/staff/absence-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.Cluster, Roles.Principals]}>
                        <SettingTabs tab="4" />
                        {/* <AbsenceList /> */}
                    </AuthGuard>
                )
            },

            {
                path: '/staff/cal-staff-ratio',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <StaffPlanningTabs tab="2" />
                        {/* <CalculationStaffRatio /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/attendance-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <StaffPlanningTabs tab="5" />
                        {/* <CalculationStaffRatio /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/list-ratio',
                element: (
                    <AuthGuard role={['*']}>
                        <SettingTabs tab="3" />
                        {/* <RatioInfo /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/list-ratio/edit',
                element: (
                    <AuthGuard role={['*']}>
                        <SettingTabs tab="3" />
                    </AuthGuard>
                )
            },
            {
                path: '/staff/qualification-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.Cluster, Roles.Principals]}>
                        <SettingTabs tab="6" />
                        {/* <QualificationList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/brand/brand-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <SettingTabs tab="7" />
                    </AuthGuard>
                )
            },
            {
                path: '/staff/relief-list',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <ReliefManagementTabs tab="1" />
                        {/* <ReliefList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/relief-ticket',
                element: (
                    <AuthGuard role={[Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <ReliefManagementTabs tab="2" />
                        {/* <ReliefTicket /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/hric-management',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin]}>
                        <SettingTabs tab="5" />
                        {/* <HricList /> */}
                    </AuthGuard>
                )
            },
            {
                path: '/staff/attendance/:reliefName/:centerName/:reliefId',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.Principals]}>
                        <Attendance typeLoadPage="private" />
                    </AuthGuard>
                )
            },
            {
                path: '/staff/timesheet-approval',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.Principals, Roles.Cluster, Roles.HrIc, Roles.HrAdmin]}>
                        <ReliefManagementTabs tab="3" />
                        {/* <TimesheetApproval /> */}
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new StaffModule();

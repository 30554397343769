import { Link as RouterLink } from 'react-router-dom';
// material-ui
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './headerStyles.scss';

// project imports
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';

// assets
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AUTH_LOCAL_STORAGE_KEY, PROFILE_MY_PLAN, RedirectPath, Roles } from 'config';
import NotificationSection from './NotificationSection';
import DashboardSection from './DashboardSection';
import StaffPlanningSection from './StaffPlanningSection';
import ReliefManagementSection from './ReliefManagementSection';
import SettingsSection from './SettingsSection';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const path = window.location.pathname;
    const isActive = (value: string) => {
        return value === path;
    };
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    {/* <LogoSection /> */}
                    <Link component={RouterLink} to={PROFILE_MY_PLAN}>
                        <Typography variant="h1" color="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
                            {' '}
                            <ManageAccountsIcon fontSize="large" />
                            KECHO
                        </Typography>
                    </Link>
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {userRole !== Roles.User && (
                <>
                    {/* dashboard-menu */}
                    <Box
                        sx={{ display: { xs: 'none', sm: 'block' }, margin: '4px 8px 4px 0px' }}
                        className={
                            isActive(RedirectPath.DASHBOARD_PRINCIPAL) ||
                            isActive(RedirectPath.DASHBOARD_HQADMIN) ||
                            isActive(RedirectPath.DASHBOARD_SIZING)
                                ? 'active'
                                : ''
                        }
                    >
                        <DashboardSection userRole={userRole} path={path} />
                    </Box>
                    {/* Staff planning menu */}
                    <Box
                        sx={{ display: { xs: 'none', sm: 'block' }, margin: '4px 8px 4px 0px' }}
                        className={
                            isActive(RedirectPath.STAFF_DEPLOYMENT) ||
                            isActive(RedirectPath.STAFF_LIST) ||
                            isActive(RedirectPath.CENTRE_LIST) ||
                            isActive(RedirectPath.CLASS_LIST) ||
                            isActive(RedirectPath.STAFF_ATTENDANCE_LIST)
                                ? 'active'
                                : ''
                        }
                    >
                        <StaffPlanningSection userRole={userRole} path={path} />
                    </Box>

                    {/* Relief management menu */}
                    <Box
                        sx={{ display: { xs: 'none', sm: 'block' }, margin: '4px 8px 4px 0px' }}
                        className={
                            isActive(RedirectPath.RELIEF_LIST) ||
                            isActive(RedirectPath.RELIEF_TICKET) ||
                            isActive(RedirectPath.TIMESHEET_LIST)
                                ? 'active'
                                : ''
                        }
                    >
                        <ReliefManagementSection userRole={userRole} path={path} />
                    </Box>

                    {/* Settings menu */}
                    <Box
                        sx={{ display: { xs: 'none', sm: 'block' }, margin: '4px 8px 4px 0px' }}
                        className={
                            isActive(RedirectPath.CLUSTER_MANAGEMENT) ||
                            isActive(RedirectPath.USER_MANAGEMENT) ||
                            isActive(RedirectPath.BRAND_MANAGEMENT) ||
                            isActive(RedirectPath.RATIO_LIST) ||
                            isActive(RedirectPath.RATIO_LIST_EDIT) ||
                            isActive(RedirectPath.ABSENCE_LIST) ||
                            isActive(RedirectPath.HRIC_MANAGEMENT) ||
                            isActive(RedirectPath.QUALIFICATION_LIST)
                                ? 'active'
                                : ''
                        }
                    >
                        <SettingsSection userRole={userRole} path={path} />
                    </Box>
                </>
            )}

            {/* notification & profile */}
            {userRole !== Roles.User && <NotificationSection />}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;

import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import '../styles.scss';

interface SizingTableProps {
    data: any;
    isLoading: boolean;
}
const SizingTable = ({ data, isLoading = false }: SizingTableProps) => {
    const { sizing } = data;

    // const handleShowEditView = (detail: any) => {
    //     UIManager.showModal({
    //         content: (onClose: any) => <EditViewModal onClose={onClose} detail={detail} />,
    //         stylesModal: {
    //             maxWidth: 900
    //         }
    //     });
    // };
    return (
        <>
            <div className="sizing-item-wrapper">
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className="sizing-title">
                            <Typography variant="h4" className="title-item-date" gutterBottom component="div">
                                {moment(data?.date).format('dddd DD/MM/YYYY ')}
                            </Typography>
                            {/* <Button variant="outlined" className="btn-edit-view" onClick={() => handleShowEditView(data)}>
                                <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                                Preview changes
                            </Button> */}
                        </div>
                        <TableContainer className="table-sizing-overall-container">
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" className="table-sizing-overall">
                                <TableHead className="table-sizing-overall-head">
                                    <TableRow>
                                        <TableCell align="left">Teacher Diploma</TableCell>
                                        <TableCell align="left">Ratio Type</TableCell>
                                        <TableCell align="center">Wakyu Calculation</TableCell>
                                        <TableCell align="center">Headcount Calculation</TableCell>
                                        <TableCell align="center">Difference</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="table-sizing-overall-body">
                                    {sizing &&
                                        sizing.map((row: any, index: number) => (
                                            <React.Fragment key={index}>
                                                {row?.ratios?.map((item: any, id: number) => (
                                                    <React.Fragment key={id}>
                                                        {item?.type === 'ECDA' ? (
                                                            <>
                                                                <TableRow style={{ backgroundColor: '#F5F5F5' }}>
                                                                    <TableCell align="left">
                                                                        <Typography variant="body2">{item?.type}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography variant="body2">{item?.wakyu}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography variant="body2">{item?.headcount}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{
                                                                                color:
                                                                                    item?.difference < 0 || item?.difference > 0
                                                                                        ? 'red'
                                                                                        : 'green'
                                                                            }}
                                                                        >
                                                                            {item?.difference}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell align="left" rowSpan={2}>
                                                                    {row?.diploma}
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Typography variant="body2">{item?.type}</Typography>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Typography variant="body2">{item?.wakyu}</Typography>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Typography variant="body2">{item?.headcount}</Typography>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{
                                                                            color:
                                                                                item?.difference < 0 || item?.difference > 0
                                                                                    ? 'red'
                                                                                    : 'green'
                                                                        }}
                                                                    >
                                                                        {item?.difference}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </div>
        </>
    );
};

export default SizingTable;

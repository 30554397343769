// project imports

// assets
import { Grid, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import UIManager from 'core/UIManager';

// project imports
import brandApi from 'services/api/brandApi';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    brandEdit?: any;
}

const DeleteBrandModal = (props: IProps) => {
    const { onClose, callback, brandEdit } = props;

    const handleDeleteCluster = async () => {
        if (brandEdit?.id) {
            const { data } = await brandApi.deleteBrand(brandEdit?.id);
            if (data) {
                UIManager.showAlert({
                    message: 'Delete successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };

    return (
        <ModalCustom
            onSave={handleDeleteCluster}
            onClose={onClose}
            onCancel={onClose}
            title="Confirm Delete"
            contentSave="Yes"
            contentCancel="Cancel"
        >
            <Grid item xs={12} style={{ padding: '20px 10px', textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom component="div">
                    Are you sure you want to delete the brand name &quot;{brandEdit?.nameBranch}&quot;?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default DeleteBrandModal;

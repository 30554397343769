// material-ui
import { Button, CardContent, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

// project imports

import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { setFilterStaffByCenter } from 'store/slices/template/center';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ClassKecho, IInputRatioSaveClass } from 'types/kecho/class-kecho';
import { IFilterRequest } from 'types/query/filter';
// import icons
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import TeacherIcon from 'assets/images/icons/teacher.svg';
import AssistantIcon from 'assets/images/icons/assistant.svg';
// assets
import { LoadingButton } from '@mui/lab';
import EmptyData from 'components/errors/empty-data';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles, formatDate, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { CentreApi, RatioApi } from 'services/api';
import { StaffKecho } from 'types/kecho/staff-kecho';
import ClearDuplicate from '../DetailPickerManual/components/ClearDuplicateModal';
import PickTime from '../DetailPickerManual/components/PickTimeModal';
import { ClassTable, StaffTable } from './Components';
import AbsenceModal from './Components/ClassTable/components/AbsenceModal';
import AddReliefModal from './Components/ClassTable/components/ReliefModal/ReliefModal';
import './styles.scss';
import CustomSelectCenter from 'components/CustomSelectCenter';

const CalStaffRatio = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filterCenter = useSelector((state) => state.center.filter);
    const [searchParams] = useSearchParams();
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;

    const idParam: any = searchParams.get('idRatio');
    const dateParam: any = searchParams.get('date');
    const centreId: any = searchParams.get('centreId');

    const defaultDate = moment().format(formatDate.DATE_DEFAULT);
    const dateFilter = dateParam ? moment(dateParam).format(formatDate.DATE_DEFAULT) : defaultDate;

    const centerFilter = filterCenter?.centerId || userCenterID || userSelectCenterID;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [classData, setClassData] = useState<ClassKecho[]>([]);
    const [staffData, setStaffData] = useState<StaffKecho[]>([]);
    const [limitData, setLimitData] = useState<number>(10);
    const [filter, setFilter] = useState<any>({
        date: dateFilter || filterCenter.date,
        centreId: centerFilter
    });
    const [filterStaff, setFilterStaff] = useState<IFilterRequest>({
        page: 1,
        take: 10,
        order: 'ASC',
        idRatio: idParam
    });
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };
    const isShowBtnAskAbsentReplicate = () => {
        return userRole === Roles.HrIc || userRole === Roles.HrAdmin;
    };

    useEffect(() => {
        handleGetCentreList();
    }, []);

    useEffect(() => {
        handleGetClassData();
    }, [filterCenter]);

    useEffect(() => {
        handleGetStaffData();
    }, [filterStaff, filterCenter]);

    const handleGetData = async () => {
        setIsLoading(true);
        await handleGetClassData();
        await handleGetStaffData();
        setIsLoading(false);
    };

    // get data
    // get data list class
    const handleGetClassData = async () => {
        setIsLoading(true);
        const requestCenterId = centerFilter;

        if (!filterCenter?.date || !requestCenterId) {
            setIsLoading(false);
            return;
        }

        const res = await RatioApi.listClass({
            ...filter,
            centreId: requestCenterId,
            date: filterCenter.date || dateFilter
        });
        if (res.dataList) {
            const dataFilter = res.dataList;
            setClassData(dataFilter);
        }
        setIsLoading(false);
    };

    // get data list staff
    const handleGetStaffData = async () => {
        setIsLoading(true);
        const requestCenterId = centerFilter;

        if (!filterCenter?.date || !requestCenterId) {
            setIsLoading(false);
            return;
        }

        const { dataList, meta } = await RatioApi.listStaffByCenter({
            ...filterStaff,
            centreId: requestCenterId,
            date: filterCenter.date
        });
        if (dataList) {
            setStaffData(dataList);
        }
        if (meta) {
            setLimitData(meta?.itemCount || 10);
        }
        setIsLoading(false);
    };

    // get data list center
    const handleGetCentreList = async () => {
        if (isShowCenterFilter()) {
            const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
            if (dataList) {
                setCentreData(dataList);
                if (!centreId) {
                    dispatch(setFilterStaffByCenter({ ...filterCenter, centerId: dataList[0]?.id, date: dateFilter }));
                } else {
                    dispatch(setFilterStaffByCenter({ ...filterCenter, centerId: centreId, date: dateFilter }));
                }
            }
        } else {
            dispatch(setFilterStaffByCenter({ ...filterCenter, centerId: userCenterID || userSelectCenterID, date: dateFilter }));
        }
    };

    // change pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setFilterStaff((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        event?.target.value && setFilterStaff((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    // action modal
    const checkLeave = async () => {
        RatioApi.checkLeave();
        await handleGetData();
    };

    const handleDuplicate = (dateFrom: string, dateTo: string, check: false, classSelect: ClassKecho) => {
        const classSelectId = classSelect?.map((item: any) => item.idClass);
        const input: IInputRatioSaveClass = {
            classIds: classSelectId,
            dateFrom,
            dateTo
        };
        if (check) return RatioApi.checkDuplicateRatio(input);
        return RatioApi.saveDuplicateRatio(input);
    };

    const handleClearDuplicate = (dateFrom: string, dateTo: string, classSelect: ClassKecho) => {
        const classSelectId = classSelect?.map((item: any) => item.idClass);
        const input = {
            classIds: classSelectId,
            fromDate: dateFrom,
            toDate: dateTo
        };
        return RatioApi.clearDuplicateRatio(input);
    };

    // handle open modal
    const handleOpenModalAskRelief = () => {
        UIManager.showModal({
            content: (onClose: any) => <AddReliefModal currentDate={filter.date} onClose={onClose} centerId={centreId || userCenterID} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleOpenModalTimeDuplicate = () => {
        UIManager.showModal({
            content: (onClose: any) => <PickTime onClose={onClose} onSave={handleDuplicate} listClass={classData} callback={checkLeave} />
        });
    };

    const handleOpenModalClearDuplicate = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <ClearDuplicate onClose={onClose} onSave={handleClearDuplicate} listClass={classData} callback={checkLeave} />
            ),
            stylesModal: {
                maxWidth: 900,
                overflowX: 'hidden'
            }
        });
    };

    const handleOpenModalAddAbsence = () => {
        UIManager.showModal({
            content: (onClose: any) => <AbsenceModal onClose={onClose} callback={handleGetData} centreId={centerFilter} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };
    const renderFilter = () => {
        return (
            <>
                <CardContent sx={{ paddingTop: '16px', paddingBottom: '15px' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: '100%', marginLeft: 0, flexWrap: 'nowrap' }}
                    >
                        <Grid container spacing={2} sx={{ height: '100%', marginTop: 0, marginLeft: 0, flexWrap: 'nowrap' }}>
                            {isShowCenterFilter() ? (
                                <Grid marginRight={1}>
                                    <CustomSelectCenter
                                        data={centreData}
                                        name="centerId"
                                        label="Center"
                                        value={String(filterCenter.centerId) || centreData[0]?.id}
                                        onChange={(e) => {
                                            dispatch(setFilterStaffByCenter({ ...filterCenter, centerId: e.target.value }));
                                            setFilterStaff({ ...filterStaff, page: 1 });
                                        }}
                                        selectProps={{
                                            MenuProps: {
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            ) : (
                                ''
                            )}
                            <Grid item style={{ padding: 0 }}>
                                <LocalDesktopDatePicker
                                    disabled={!centreData.length && !userCenterID}
                                    isRequired
                                    errorTextSx={{
                                        top: '50px'
                                    }}
                                    sx={{ width: '160px' }}
                                    label="Date"
                                    views={['year', 'month', 'day']}
                                    value={filter.date}
                                    onChange={(newValue) => {
                                        setFilter((prev: any) => {
                                            return { ...prev, date: moment(newValue).format('YYYY/MM/DD') };
                                        });

                                        if (!newValue) {
                                            setClassData([]);
                                            setStaffData([]);
                                            return;
                                        }
                                        dispatch(setFilterStaffByCenter({ ...filterCenter, date: moment(newValue).format('YYYY/MM/DD') }));
                                    }}
                                    onError={() => {
                                        setClassData([]);
                                        setStaffData([]);
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ padding: 0 }}>
                                <LoadingButton
                                    disabled={!centreData.length && !userCenterID}
                                    size="medium"
                                    loading={!!isLoading}
                                    variant="outlined"
                                    style={{
                                        marginLeft: 8,
                                        color: '#3F51B5',
                                        border: '1px solid #3F51B5',
                                        whiteSpace: 'nowrap'
                                    }}
                                    onClick={() => {
                                        setFilter({ date: defaultDate, page: 0, take: 10, centreId: filter?.centreId });
                                        dispatch(
                                            setFilterStaffByCenter({
                                                ...filterCenter,
                                                date: defaultDate,
                                                centerId: filterCenter.centerId || filter?.centreId
                                            })
                                        );
                                    }}
                                    startIcon={<ReplayIcon color="action" style={{ color: '#3F51B5' }} />}
                                >
                                    Reset date
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ textAlign: 'right' }} justifyContent="flex-end">
                            {isShowCenterFilter() ? null : (
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    style={{ marginRight: 8, color: '#3F51B5', border: '1px solid #3F51B5' }}
                                    onClick={handleOpenModalAskRelief}
                                >
                                    Ask a relief
                                </Button>
                            )}
                            {!isShowBtnAskAbsentReplicate() && (
                                <>
                                    <Button
                                        size="medium"
                                        variant="outlined"
                                        style={{ marginRight: 8, color: '#3F51B5', border: '1px solid #3F51B5' }}
                                        disabled={classData.length <= 0}
                                        startIcon={<DeleteOutlinedIcon />}
                                        onClick={handleOpenModalClearDuplicate}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        size="medium"
                                        variant="outlined"
                                        style={{ marginRight: 8, color: '#3F51B5', border: '1px solid #3F51B5' }}
                                        disabled={classData.length <= 0}
                                        onClick={handleOpenModalTimeDuplicate}
                                        startIcon={<ContentCopyIcon />}
                                    >
                                        Replicate
                                    </Button>
                                    <Button
                                        size="medium"
                                        variant="outlined"
                                        style={{ backgroundColor: '#3F51B5', outline: 'none', color: 'white' }}
                                        disabled={classData.length <= 0}
                                        onClick={handleOpenModalAddAbsence}
                                        startIcon={<AddIcon />}
                                    >
                                        Add absence
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        );
    };

    const renderLegend = () => {
        return (
            <>
                <CardContent sx={{ paddingTop: '0px', paddingBottom: '15px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                            <img src={TeacherIcon} alt="teacher" style={{ marginRight: 5, width: 20 }} />{' '}
                            <span style={{ fontWeight: 600 }}>Teacher</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={AssistantIcon} alt="assistant" style={{ marginRight: 5, width: 20 }} />{' '}
                            <span style={{ fontWeight: 600 }}>Assistant Teacher</span>
                        </div>
                    </div>
                </CardContent>
            </>
        );
    };
    const renderClassList = () => {
        return (
            <>
                <CardContent style={{ paddingTop: 0 }}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2} style={{ width: '100%', marginLeft: 0 }}>
                        <Grid item xs={12} sm={12} style={{ paddingLeft: 0 }}>
                            {classData && classData?.length > 0 ? (
                                <ClassTable rows={classData} date={filter.date} centerId={centerFilter} handleGetData={handleGetData} />
                            ) : (
                                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {isLoading ? <CircularProgress /> : <EmptyData />}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        );
    };

    const renderStaffList = () => {
        return (
            <>
                <hr className="MuiDivider-root MuiDivider-fullWidth css-vve0hc-MuiDivider-root" />
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            {staffData && staffData?.length > 0 ? (
                                <StaffTable
                                    rows={staffData}
                                    page={filterStaff.page - 1}
                                    take={filterStaff.take}
                                    limitData={limitData}
                                    currentDate={filter.date}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            ) : (
                                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {isLoading ? <CircularProgress /> : <EmptyData />}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </>
        );
    };

    return (
        <Grid className="container">
            <div style={{ backgroundColor: 'white' }}>
                {renderFilter()}
                {renderLegend()}
                {renderClassList()}
                {renderStaffList()}
            </div>
        </Grid>
    );
};

export default CalStaffRatio;

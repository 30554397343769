import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'brand/';

const brandApi = {
    listBrand(params?: any): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, params);
        return axiosAuthClient.get(url);
    },
    createBrand(body: any): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    getDetailsBrandById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    deleteBrand(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },
    updateBrand(id: string, body: any): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    }
};
export default brandApi;

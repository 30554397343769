import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

interface ICustomSelect extends SelectProps {
    onChange: (e: any) => void;
    value: String | undefined | null;
    data: Object[];
    defaultValue?: string;
    name: string;
    label: string;
    size?: 'small' | 'medium';
    componentSx?: Object;
    selectProps?: SelectProps;
}

const CustomSelectCluster = ({
    onChange,
    data,
    value,
    defaultValue,
    name,
    label,
    size = 'small',
    componentSx,
    selectProps
}: ICustomSelect) => {
    const handleOnChange = (e: any) => {
        onChange && onChange(e);
    };
    return (
        <FormControl variant="outlined" sx={{ minWidth: 280, maxWidth: 360, ...componentSx }}>
            <InputLabel size="small" id={`${name}-label`}>
                {label}
            </InputLabel>
            <Select
                labelId={`${name}-label`}
                id={name}
                name={name}
                label={label}
                value={defaultValue}
                onChange={handleOnChange}
                size={size}
                {...selectProps}
            >
                {data.map((cluster: any) => (
                    <MenuItem key={cluster.id} value={cluster.id}>
                        {cluster.nameCluster}
                    </MenuItem>
                ))}
                {data.length === 0 && <MenuItem>No data</MenuItem>}
            </Select>
        </FormControl>
    );
};

export default CustomSelectCluster;

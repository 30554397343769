import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';
import Principal from '../PrincipalDashboard';
import HQAdmin from '../HqadminDashboard';
import Sizing from '../SizingDashboard';
import DownloadReports from '../DownloadReports';
import './styles.scss';
import { AUTH_LOCAL_STORAGE_KEY, RedirectPath, Roles } from 'config';
import { useNavigate } from 'react-router-dom';

interface ReliefManagementTabsProps {
    tab?: string;
}
const DashboardTabs = ({ tab }: ReliefManagementTabsProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const path = window.location.pathname;
    const navigate = useNavigate();
    const [value, setValue] = React.useState(tab || '1');

    useEffect(() => {
        if (path.includes(RedirectPath.DASHBOARD_HQADMIN)) {
            setValue('2');
        } else if (path.includes(RedirectPath.DASHBOARD_SIZING)) {
            setValue('3');
        } else if (path.includes(RedirectPath.DASHBOARD_REPORTS)) {
            setValue('4');
        } else {
            setValue('1');
        }
    }, [path]);
    const handleChangeTabRelief = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            navigate(RedirectPath.DASHBOARD_PRINCIPAL);
        } else if (newValue === '2') {
            navigate(RedirectPath.DASHBOARD_HQADMIN);
        } else if (newValue === '3') {
            navigate(RedirectPath.DASHBOARD_SIZING);
        } else if (newValue === '4') {
            navigate(RedirectPath.DASHBOARD_REPORTS);
        }
    };

    const isShowTabPrincipal = () => {
        return userRole !== Roles.User;
    };
    const isShowTabHqAdmin = () => {
        return userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };
    return (
        <div className="dashboard-tab-management">
            <TabContext value={value}>
                <TabList onChange={handleChangeTabRelief} className="dashboard-tab-list">
                    {isShowTabPrincipal() && <Tab label="Center Leader" value="1" />}
                    {isShowTabHqAdmin() && <Tab label="HQ Admin" value="2" />}
                    {isShowTabPrincipal() && <Tab label="Sizing page" value="3" />}
                    {isShowTabPrincipal() && <Tab label="Download Reports" value="4" />}
                </TabList>
                <TabPanel value="1" className="dashboard-panel principal-panel">
                    <Principal />
                </TabPanel>
                <TabPanel value="2" className="dashboard-panel hqadmin-panel">
                    <HQAdmin />
                </TabPanel>
                <TabPanel value="3" className="dashboard-panel sizing-panel">
                    <Sizing />
                </TabPanel>
                <TabPanel value="4" className="dashboard-panel reports-panel">
                    <DownloadReports />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default DashboardTabs;
